// Chart Js Data
import useConvertDuration from './useConvertDuration'
import { getProjectIssueStatisticAPI } from '@/use/useGitlabApi.js'


// Donut chart
export const useIssueCountByMember = (data) => {
    let result = {
        datasets: [{
            data: [],
            backgroundColor: [
                "#00d1b2",
                "#209cee",
                "#ffdd56",
                "#ff3860",
                "#7FDBFF",
                "#B10DC9",
                "#FFDC00",
            ]
        }], labels: []
    }

    if (data) {
        for (const key in data) {
            result.labels.push(data[key]['name'])
            result.datasets[0].data.push( data[key]['issues'].length )
        }
    }

    return result
}


export const useIssueSpentByMember = (data) => {
    let result = {
        datasets: [{
            data: [],
            backgroundColor: [
                "#00d1b2",
                "#209cee",
                "#ffdd56",
                "#ff3860",
                "#7FDBFF",
                "#B10DC9",
                "#FFDC00",
            ]
        }], labels: []
    }

    if (data) {
        for (const key in data) {
            result.labels.push(data[key]['name'])
            result.datasets[0].data.push( useConvertDuration(data[key]['spend']) )
        }
    }

    return result
}


export const useIssueStateByMember = (data) => {

    let result = {
        labels: [],
        datasets: [
            {
                label: "Opened",
                backgroundColor: '#ff3860',
                data: [],
            },
            {
                label: "Closed",
                backgroundColor: '#00d1b2',
                data: [],
            }
        ],
    }

    result.datasets[0].data = []
    result.datasets[1].data = []

    if (data) {
        for (const key in data) {
            result.labels.push(data[key]['name'])
            result.datasets[0].data.push(data[key]['opened'])
            result.datasets[1].data.push(data[key]['closed'])
        }
    }

   return result
}