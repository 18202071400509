import { computed, reactive, toRefs} from "vue";
import { useQuery, useResult } from '@vue/apollo-composable'

import { GET_ALL_PROJECTS, GET_PROJECT_QUERY, GET_PROJECT_INFO, GET_PROJECTS_QUERY } from '@/graphql/queries/projectQuery'

// remove soon
import { handleFilter } from "@/use/useFilter";
import { useFilterMembers } from "@/use/useMembers";
// --- remove soon

import { getProjectsAPI, getProjectMembersAPI, getProjectIssueStatisticAPI, getProjectAPI, getProjectEventsApi } from '@/use/useGitlabApi'
import { useIssueCountByMember, useIssueSpentByMember, useIssueStateByMember } from "@/use/useChart";

import useConvertDuration from '@/use/useConvertDuration'

import moment from 'moment'


// old function/
export const useProjectApi = async (path="") => {
  const state = reactive({
    title: '',
    project: null,
    members: null,
    participants: null,
    team_count: 0,
    total_spent: 0,
    total_issue: 0
  });

  const variables = reactive({
    path: path
  })


  const {
    getStartDate: startDate,
    getEndDate: endDate,
    getFilteredIssues: filterIssues,
    getSelectedMembers: selectedMembers,
    setFilteredIssues,
    setFilteredMembers,
    setTotalTimeSpend,
    setClosedIssues,
    setOpentIssues,
    setComplete,
    setDonutSpinner,
    setDonutTimeSpend,
    getTotalTimeSpend,
    setIssuesStats
  } = handleFilter()


  const { onResult, loading, error, refetch } = useQuery(
    GET_PROJECT_QUERY,
    () => ({
      path: variables.path
    }),
    {
      fetchPolicy: 'cache-and-network'
    })


  const members = await getProjectMembersAPI(encodeURIComponent(path));
  state.participants = members

  onResult(queryResult => {

    if (queryResult.data) {
      const { project } = queryResult.data
      state.project = project
      state.title = project.name


      // console.log(state.participants)

      state.members = useFilterMembers(project.issues.nodes, state.participants )

      state.total_issue = project.issues.nodes.length

      setFilteredMembers(state.members)
      setFilteredIssues(project.issues.nodes, state.members)
      setTotalTimeSpend(project.issues.nodes)
      setClosedIssues(project.issues.nodes)
      setOpentIssues(project.issues.nodes)
      setComplete(project.issues.nodes)

      state.total_spent = getTotalTimeSpend


      let donutSpinner = useIssueCountByMember(filterIssues.value)
      let donutTimeSpend = useIssueSpentByMember(filterIssues.value)
      let barStackedIssues = useIssueStateByMember(state.members, path)


      setDonutSpinner(donutSpinner)
      setDonutTimeSpend(donutTimeSpend)
      setIssuesStats(barStackedIssues)

    }
  })

  function refetchProject() {
    // refetch({
    //   startDate: start,
    //   endDate: end
    // })

    refetch()
  }


  return {
    ...toRefs(state),
    loading,
    error,
    refetchProject
  }
}
// -- old function

// Get projects list
// From CMS
export const getAllProjects = () => {

  const state = reactive({
    selected:[],
    cmsprojects:[],
    projects: [],
    filtered_projects: [],
    filter: ''
  })


  const { onResult, loading, error, refetch } = useQuery( GET_ALL_PROJECTS, null, {
    clientId: "cmsClient"
  })

  onResult(queryResult => {

    if ( queryResult.data ) {
        // let _projects = queryResult.data.projects

        state.cmsprojects = queryResult.data.projects
        state.projects = queryResult.data.projects
        state.filtered_projects = queryResult.data.projects

        // _projects.forEach(item => {
        //   state.selected.push(item.path)
        // })

        // fetchProjectSelectedGitlab ()
    }
  })

  // const projects = useResult(result, null, data => data.projects )

  const fetchProjectSelectedGitlab = async () => {
    //getProjectAPI
    const res = await getProjectAPI(state.selected)
    let payload = []

    if (res) {

      res.forEach((item) => {

        let found = state.cmsprojects.find(element => element.path === item.path_with_namespace );

        if ( found) {
          let _payload = Object.assign({}, found, {
              created_at:  moment(item.created_at).format('DD MMM YYYY'),
              id: item.id,
              last_activity_at: moment(item.last_activity_at).format('DD MMM YYYY'),
              open_issues_count: item.open_issues_count
          })
          payload.push(_payload)
        } else {
          console.log('not found')
        }

      })
    }

    state.projects = payload
    state.filtered_projects = payload

  }

  const filterProjectsHandle = (val) => {
    if ( val === '') {
      state.filtered_projects = state.projects
    } else {
      state.filtered_projects = state.projects.filter(project => project.name.toLowerCase().includes(val.toLowerCase()))
    }
  }


  return {
    loading,
    error,
    refetch,
    filterProjectsHandle,
    ...toRefs(state)
  }
}


// Get project info
export const getProjectInfoApi = (path="") => {

  const { result, loading, error } = useQuery(
    GET_PROJECT_INFO,
    () => ({
      path: path,
    }),
    {
      fetchPolicy: 'cache-and-network'
    })

  const project = useResult(result, null, data => data.project );

  return {
    project,
    loading,
    error
  }

}


export const getProjectIssues = (path="", sdate ="1900-01-01", edate="2900-01-01") => {


  const state = reactive({
    issues: []
  })

  const variables = reactive({
    path: path,
    sdate: sdate,
    edate: edate
  })

  const { onResult, loading, error, refetch } = useQuery(
    GET_PROJECT_QUERY,
    () => ({
      path: variables.path,
      startDate: variables.sdate,
      endDate: variables.edate
    }),
    {
      fetchPolicy: 'cache-and-network'
  })

  // const project = useResult(result, null, data => data.project );
  // const issues = useResult(result, null, data => data.project.issues.nodes );

  onResult(queryResult => {

    if ( queryResult.data ) {
      let { project : { issues : { nodes : nodes } } } = queryResult.data

      state.issues = []

      nodes.forEach(node => {

        let assignees = node.assignees
        let payload = Object.assign({}, node)

        if ( typeof assignees.nodes[0] !== 'undefined') {
          payload.assignee = assignees.nodes[0].name
        } else {
          payload.assignee ='N/A'
        }

        payload.cdate = moment(node.createdAt).format('DD MMM YYYY')
        payload.udate = moment(node.updatedAt).format('DD MMM YYYY')
        payload.spent = useConvertDuration(node.totalTimeSpent)

        state.issues.push(payload);
      });
    }
  })


  const refetchProject = (start, end) => {

    variables.edate = end
    variables.sdate = start
    refetch()
  }


    return {
      loading,
      error,
      refetch,
      refetchProject,
      ...toRefs(state)
    }

}

export const getProjectStatsApi = (path="") => {

  const state = reactive({
    opened: 0,
    closed: 0,
    all: 0,
    timetotal: 0,
    issues: [],
    assignees: [],
    grouped: [],
    issuesCountByMembers: [],
    issuesTimeByMembers: [],
    issuesStateByMembers: []
  });


  // methods
  // fetch stats
  const fetchProjectStats = async () => {
    try {
      const stats = await getProjectIssueStatisticAPI(encodeURIComponent(path))
      if (stats) {
        state.opened = stats.opened
        state.closed = stats.closed
        state.all = stats.all
      }
    }
    catch (err) {
      console.log( 'project stats: ' + err)
      throw new Error(err);
    }
  }

  // set group issues by user
  const groupIssuesByUser = () => {
    const { assignees, issues } = state
    state.grouped = assignees.reduce((acc, person) => {
        let key = person.replace(/\s+/g, '').toLowerCase();

        if (!acc[key]) {
          acc[key] = {}
        }

        let issueList = issues.filter(issue => {
          let { assignees: { nodes } } = issue
          if( nodes.length > 0) {
            return nodes[0].name === person
          }
        });

        let totalSpend = issueList.reduce((total, issue) => {

          let { assignees: { nodes } } = issue
          if( nodes.length > 0 && nodes[0].name === person) {
            return total + issue.totalTimeSpent
          }

        },0)


        let opened = issueList.reduce((st, issue) => {
          let { assignees: { nodes } } = issue
          if( nodes.length > 0 && nodes[0].name === person && issue.state === 'opened') {
            st = st + 1
          }
          return st
        },0)


        let closed = issueList.reduce((st, issue) => {
          let { assignees: { nodes } } = issue
          if( nodes.length > 0 && nodes[0].name === person && issue.state === 'closed') {
            st = st + 1
          }
          return st
        },0)



        acc[key].issues = issueList
        acc[key].name = person
        acc[key].spend = totalSpend
        acc[key].opened = opened
        acc[key].closed = closed

      return acc
    }, {})
  }


  // project query

  const { onResult, loading, error, refetch } = useQuery(
    GET_PROJECT_QUERY,
    () => ({
      path: path
    }),
    {
      fetchPolicy: 'cache-and-network'
  })

  onResult(queryResult => {

    if ( queryResult.data ) {
      let { project : { issues : { nodes : nodes } } } = queryResult.data

      state.issues = nodes

      // get total time
      state.timetotal = nodes.reduce((total, issue) => {
        return total + issue.totalTimeSpent
      }, 0)

      // get assignees
      state.assignees = nodes.reduce((accumulator, issue) => {

        let item = issue.assignees.nodes;
        if( item.length > 0) {
          if (accumulator.indexOf(item[0].name) === -1) {
            accumulator.push(item[0].name)
          }
        }
        return accumulator
      },[])


      // fetch stats
      fetchProjectStats()
      // group issues by users
      groupIssuesByUser()

      // setting charts data
      state.issuesCountByMembers = useIssueCountByMember(state.grouped)
      state.issuesTimeByMembers = useIssueSpentByMember(state.grouped)
      state.issuesStateByMembers = useIssueStateByMember(state.grouped)

    }
  })


  // console.log(state)

  return {
    loading,
    error,
    refetch,
    ...toRefs(state)
  }

}

export const getProjectEventsStatApi = (path="") => {

  const state = reactive({
    loading: false,
    events: []
  });

  // getProjectEventsApi

  const fetchProjectEvents = async () => {
    try {
      state.loading = true
      const res = await getProjectEventsApi(encodeURIComponent(path))

      if (res) {
        state.events = res
      }
    }
    catch (err) {
      console.log(err)
      throw new Error(err);
    }
    finally {
      state.loading = false
    }
  }

  fetchProjectEvents()

  // console.log(state)

  return {
    ...toRefs(state)
  }

}
